import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

import LogoImage from '@images/logo-horizontal.svg';
import FacebookIcon from '../../images/icon-facebook.svg';
import InstagramIcon from '../../images/icon-instagram.svg';
import YoutubeIcon from '../../images/icon-youtube.svg';

const WhiteFooter = styled.footer`
  background-color: #ffffff;
  padding: 2rem 0;
  text-align: center;
`;

const Logo = styled(LogoImage)`
  height: 30px;

  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

const FooterMenuWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterMenuItem = styled.li`
  padding .5rem 0;

  @media (min-width: 1024px) {
    padding: 1.5rem 0 .75rem 1rem;
    display: inline-block;
    &:not(:last-child):after {
      content: "|";
      display: inline-block;
      padding-left: 1rem;
      color: #1c7cd5;
    }
  }
`;

const SocialMediaBtn = styled.svg`
  height: 36px;
  width: 36px;
`;

const Footer = () => {
  const { t } = useTranslation();

  const options = [
    // { label: t('contact_us'), href: '/contact_us/' },
    { label: t('terms_and_conditions'), href: '/terms_and_conditions/' },
    { label: t('privacy_policy'), href: '/privacy_notice/' },
    { label: t('return_and_cancellation'), href: '/return_and_cancelation/' },
  ];

  return (
    <WhiteFooter>
      <Container>
        <Link to="/">
          <Logo/>
        </Link>

        <FooterMenuWrapper>
          {options.map((item, index) => (
            <FooterMenuItem key={index}>
              <Link to={item.href}>
                {item.label}
              </Link>
            </FooterMenuItem>
          ))}
        </FooterMenuWrapper>

        <hr/>

        <div className="justify-content-center mt-4">
          <a
            href="https://www.facebook.com/islaceloficial"
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaBtn as={FacebookIcon}/>
          </a>

          <a
            href="https://instagram.com/islaceloficial"
            target="_blank"
            rel="noreferrer"
            className="ms-2"
          >
            <SocialMediaBtn as={InstagramIcon}/>
          </a>

          <a
            href="https://www.youtube.com/channel/UCTY_Yis-aqfDckRJvn5pMXw"
            target="_blank"
            rel="noreferrer"
            className="ms-2"
          >
            <SocialMediaBtn as={YoutubeIcon}/>
          </a>
        </div>

        <div className="mt-4 text-muted">
          © {new Date().getFullYear()} aVenezuela.{' '}
          <br className="d-block d-md-none" />
          {t('all_rights_reserved')}
        </div>
      </Container>
    </WhiteFooter>
  );
};

export default Footer;
